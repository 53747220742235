import colors from '@nib-components/colors';

export const addColorStyleToAnchorTags = (input: string) => {
  if (typeof window !== 'undefined') {
    const doc = new window.DOMParser().parseFromString(input, 'text/html');

    const anchorTags = doc.getElementsByTagName('a');
    const numberOfAnchorTags = anchorTags.length;
    for (let i = 0; i < numberOfAnchorTags; i++) {
      const anchorTag = anchorTags.item(i);
      if (anchorTag !== null) {
        anchorTag.style.color = colors.colorTrueGreen;
      }
    }

    const body = doc.body.innerHTML;
    return body;
  } else {
    return input;
  }
};
