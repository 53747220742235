import React, { useState } from 'react';

import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { pb, pt, px, py, Padding } from 'styled-components-spacing';
import { chunk } from 'lodash';

import Card from '@nib/card';
import { Section, Stack, Box, Tiles, Columns, Column } from '@nib/layout';

import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import colors from '@nib-components/colors';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';

import HeroPanel from '../components/HeroPanel';
import Layout from '../components/Layout';
import MemberStory from '../components/MemberStory';
import CallBackModuleBase from '../components/CallBackModal';
import UtilityButtons from '../components/UtilityButtons';
import { GridImageWrapper, HeroPanelCopy } from '../components/styledComponents';
import { urlConstants } from '../constructs/constants';
import {
  ContentfulArticleCardNode,
  ContentfulDocument,
  ContentfulMemberStoryNode,
  ContentfulSupplierNode,
  UtilityLinkModel,
} from '../constructs/models';
import metrics from '../metrics';

import desktop from '../img/health-and-wellbeing/hero/desktop.jpg';
import desktop2x from '../img/health-and-wellbeing/hero/desktop@2x.jpg';
import mobile from '../img/health-and-wellbeing/hero/mobile.jpg';
import mobile2x from '../img/health-and-wellbeing/hero/mobile@2x.jpg';
import tablet from '../img/health-and-wellbeing/hero/tablet.jpg';
import tablet2x from '../img/health-and-wellbeing/hero/tablet@2x.jpg';
import healthManagementProgrammes600w from '../img/health-and-wellbeing/health-management-programmes-600x590.jpg';
import healthManagementProgrammes343w from '../img/health-and-wellbeing/health-management-programmes-343x200.jpg';

import { addColorStyleToAnchorTags } from '../utils/html-string';

export const healthAndWellbeingPageQuery = graphql`
  query HealthAndWellbeingPageQuery {
    allContentfulArticleCard(sort: { fields: order, order: ASC }) {
      edges {
        node {
          image {
            file {
              url
            }
          }
          title
          linkText
          linkUrl
          order
        }
      }
    }
    allContentfulSupplier(sort: { fields: order, order: ASC }) {
      edges {
        node {
          logo {
            file {
              url
            }
          }
          linkUrl
          name
          text {
            childMarkdownRemark {
              html
            }
          }
          order
        }
      }
    }
    allContentfulMemberStory(filter: { isHeroStory: { eq: true } }) {
      edges {
        node {
          videoUrl
          title
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulDocument(filter: { name: { eq: "Health Programme Terms" } }, limit: 1) {
      edges {
        node {
          document {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  }
`;

interface allContentfulArticleCardGraphQl {
  edges: { node: ContentfulArticleCardNode }[];
}

interface allContentfulSupplierGraphQl {
  edges: { node: ContentfulSupplierNode }[];
}

interface allContentfulMemberStoryGraphQl {
  edges: { node: ContentfulMemberStoryNode }[];
}

interface allCOntentfulDocumentStoryGraphQl {
  edges: { node: { document: ContentfulDocument } }[];
}

interface ArticleSectionProps {
  articleCards: { node: ContentfulArticleCardNode }[];
}

const ArticleSection = ({ articleCards }: ArticleSectionProps): JSX.Element => (
  <Section role="section">
    <Stack space={4}>
      <Box textAlign="center">
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
          Get the latest on health and wellbeing
        </Heading>
      </Box>
      <Tiles columns={{ xs: 1, md: 2, lg: 3 }} space={{ xs: 4, md: 6 }} flex>
        {articleCards.map((element, index) => (
          <Card image={element.node.image.file.url} align="center" key={index}>
            <Card.Content title={element.node.title} />
            <Card.Footer>
              <PrimaryButton href={element.node.linkUrl}>{element.node.linkText}</PrimaryButton>
            </Card.Footer>
          </Card>
        ))}
        ;
      </Tiles>
    </Stack>
  </Section>
);

const HealthManagementProgrammeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'gridItem1'
    'gridItem3'
    'gridItem2';
  ${breakpoint('lg')`
    ${py(6)};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'gridItem1 gridItem3'
      'gridItem2 gridItem3'
  `};
`;

const GridItem1 = styled.div`
  grid-area: gridItem1;
  ${pb(6)};
  ${breakpoint('lg')`
    ${px(4)};
    ${pb(0)};
  `};
`;

const GridItem2 = styled.div`
  grid-area: gridItem2;
  ${breakpoint('lg')`
    ${pt(6)};
    ${px(4)};
  `};
`;

const GridItem3 = styled.div`
  grid-area: gridItem3;
  display: grid;
  ${breakpoint('lg')`
    min-height: 475px;
  `};
`;

const SecondaryOutlineButton = styled(SecondaryButton)`
  margin-bottom: 10px;
  ${breakpoint('md')`
    margin-right: 5px;
  `};
  ${breakpoint('lg')`
    margin-left: 0px;
    margin-right: 10px;
  `};
  min-width: 210px;
`;

const PrimaryOutlineButton = styled(PrimaryButton)`
  margin-bottom: 10px;
  ${breakpoint('md')`
    margin-left: 5px;
  `};
  ${breakpoint('lg')`
    margin-left: 0px;
    margin-right: 0px;
  `};
  min-width: 210px;
`;

const ButtonWrapper = styled.div`
  ${py(4)};
  text-align: center;
  display: block;
  ${breakpoint('md')`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `};
  ${breakpoint('lg')`
    display: block;
    text-align: left;
  `};
`;

const HealthManagementProgrammeImage = styled.img`
  width: 100%;
  height: auto;

  ${breakpoint('lg')`
    position: absolute;
    ${(props) =>
      !props.thin
        ? css`
            height: 100%;
            width: auto;
          `
        : css`
            width: 100%;
          `}
  `};
`;

interface HealthManagmentProgrammeSectionProps {
  healthProgrammeTerms: ContentfulDocument;
}
const HealthManagementProgrammeSection = ({
  healthProgrammeTerms,
}: HealthManagmentProgrammeSectionProps): JSX.Element => (
  <Section role="section" background="trueGreen">
    <HealthManagementProgrammeGrid>
      <GridItem1>
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading color="white" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
            nib Health Management Programmes
          </Heading>
          <Copy color="white" measure={false}>
            A few lifestyle changes can help you live a healthier life. We offer Health Management
            Programmes for conditions like joint pain, heart health, or cancer treatment* .
          </Copy>
          <Copy color="white" measure={false}>
            These programmes give you the tools you need to feel better and improve your wellbeing.
            They’re available for eligible members at no additional cost and are run by nib along
            with a range of healthcare providers.
          </Copy>
        </Stack>
      </GridItem1>
      <GridItem2>
        <ButtonWrapper>
          <SecondaryOutlineButton href="/programme-enquiry-form" component="a" color="light">
            Take the first step
          </SecondaryOutlineButton>
          <PrimaryOutlineButton href="/health-management-programmes" component="a">
            Find out more
          </PrimaryOutlineButton>
        </ButtonWrapper>
        <Copy small color="white" measure={false}>
          *
          <Link color="light" href={healthProgrammeTerms.file.url}>
            Check the criteria to see whether you’re eligible for a programme.
          </Link>
        </Copy>
      </GridItem2>
      <GridItem3>
        <GridImageWrapper>
          <picture>
            <source media="(max-width:640px)" srcSet={`${healthManagementProgrammes343w}`} />
            <HealthManagementProgrammeImage
              src={healthManagementProgrammes600w}
            ></HealthManagementProgrammeImage>
          </picture>
        </GridImageWrapper>
      </GridItem3>
    </HealthManagementProgrammeGrid>
  </Section>
);

const BoxWrapper = styled(Box)`
  box-sizing: border-box;
`;

interface SupplierTileProps {
  linkUrl: string;
  logo: string;
  name: string;
  text: string;
}

const SupplierTile = ({ linkUrl, logo, name, text }: SupplierTileProps): JSX.Element => (
  <BoxWrapper
    padding={5}
    paddingVertical={{ lg: 6 }}
    height="100%"
    style={{ boxShadow: `${colors.sneezy} 0px 4px 8px 0px` }}
  >
    <a href={linkUrl} target="_blank">
      <div style={{ height: '200px', width: '100%' }}>
        <img src={logo} style={{ height: '100%', width: '100%', objectFit: 'contain' }}></img>
      </div>
    </a>
    <Padding top={4}>
      <Box textAlign="center">
        <Heading size={3}>{name}</Heading>
      </Box>
    </Padding>
    <Copy
      align="left"
      measure={false}
      component="div"
      dangerouslySetInnerHTML={{ __html: addColorStyleToAnchorTags(text) }}
    />
  </BoxWrapper>
);

interface SupplierSectionProps {
  suppliers: { node: ContentfulSupplierNode }[];
  memberStory: ContentfulMemberStoryNode;
}

const SupplierSection = ({ suppliers, memberStory }: SupplierSectionProps): JSX.Element => (
  <Section role="section">
    <Box textAlign="center" marginBottom={4}>
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
        Get more from your cover
      </Heading>
    </Box>
    <Copy align="center" measure={false}>
      As an nib member you can access tools and special offers through providers we work with to
      help you take control of your health, happiness and lifestyle.
    </Copy>
    <Padding vertical={5}>
      <Stack space={5}>
        {chunk(suppliers, 3).map((chunk) => {
          return (
            <Columns collapseBelow="lg" space={{ xs: 4, md: 6 }} align="center">
              {chunk.map((element, index) => (
                <Column flex width="1/3">
                  <SupplierTile
                    linkUrl={element.node.linkUrl}
                    logo={element.node.logo.file.url}
                    name={element.node.name}
                    text={element.node.text.childMarkdownRemark.html}
                    key={index}
                  />
                </Column>
              ))}
            </Columns>
          );
        })}
      </Stack>
    </Padding>
    <MemberStory memberStory={memberStory} showButton={true} />
  </Section>
);

interface HealthAndWellbeingPageProps {
  data: {
    allContentfulArticleCard: allContentfulArticleCardGraphQl;
    allContentfulSupplier: allContentfulSupplierGraphQl;
    allContentfulMemberStory: allContentfulMemberStoryGraphQl;
    allContentfulDocument: allCOntentfulDocumentStoryGraphQl;
  };
}

const utilityLinks = (onNeedHelpClick: () => any): UtilityLinkModel[] => [
  {
    subtitle: 'Need help?',
    title: 'Contact a Wellness Coach',
    onClick: onNeedHelpClick,
  },
  {
    subtitle: 'Looking for your details?',
    title: 'Log in to my nib',
    url: urlConstants.mynib,
  },
  {
    subtitle: 'Had treatment?',
    title: 'Send us your claim',
    url: '/claims',
  },
  {
    subtitle: 'Want to manage your health?',
    title: 'Find out more',
    url: '/health-management-programmes',
  },
];

const UtilityButtonsSection = (): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  return (
    <Section role="section" background="warmWhite60">
      <CallBackModuleBase variant="Wellness Coach" isOpen={isOpen} setOpen={setOpen} />
      <UtilityButtons buttons={utilityLinks(openModal)} flex={true} />
    </Section>
  );
};

const title = 'Health And Wellbeing';
const description =
  'Get more from your health insurance cover with nib. We can help you manage your health, live a healthier life and reduce the risk of illness.';

const HealthAndWellbeingPage = ({ data }: HealthAndWellbeingPageProps): JSX.Element => {
  console.log('health and wellbeing props', data);

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/health-and-wellbeing',
    url: 'https://www.nib.co.nz/health-and-wellbeing',
    description:
      'Life Insurance and Living Insurance Quote | nibNew Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.',
    name: ['Life Insurance and Living Insurance Quote | nib'],
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <HeroPanel
        images={{
          desktop: desktop,
          desktop2x: desktop2x,
          mobile: mobile,
          mobile2x: mobile2x,
          tablet: tablet,
          tablet2x: tablet2x,
        }}
        title="Health and wellbeing"
        variation="condensed"
      >
        <HeroPanelCopy>
          Get more from your cover with nib. With nib health cover you can get access to our Health
          Management Programmes and special offers through our partners.
        </HeroPanelCopy>
      </HeroPanel>
      <ArticleSection articleCards={data.allContentfulArticleCard.edges} />
      <HealthManagementProgrammeSection
        healthProgrammeTerms={data.allContentfulDocument.edges[0].node.document}
      />
      <SupplierSection
        suppliers={data.allContentfulSupplier.edges}
        memberStory={data.allContentfulMemberStory.edges[0].node}
      />
      <UtilityButtonsSection />
    </Layout>
  );
};

export default metrics({ pageName: 'using-cover' })(HealthAndWellbeingPage);
